import React, { useMemo } from 'react'
import {Center, ColorSchemeProvider, Container, createStyles, MantineProvider, Title} from '@mantine/core'
import TramitaHeader from '../Header'
import { FooterLinks } from '../Footer'
import { IntlProvider, useIntl } from 'gatsby-plugin-intl'
import { useTheme } from '../../providers/ThemeProvider'
import './index.css'

const useStyles = createStyles((theme) => ({
  footerPadding: {
    paddingBottom: `2rem`,
    [theme.fn.largerThan('sm')]: {
      paddingBottom: `4rem`,
    },
    [theme.fn.largerThan('lg')]: {
      paddingBottom: `6rem`,
    },
  },
}))

const LayoutComponent = (props) => {
  const { classes } = useStyles()
  const { colorScheme } = useTheme()
  const intl = useIntl()

  const headerLinks = useMemo(
    () => [
      { link: '/', label: intl.formatMessage({ id: `components.layout.index.home` }) },
      { link: '/contact', label: intl.formatMessage({ id: `components.layout.index.contact` }) },
      // { link: '/about', label: localIntl.formatMessage({ id: `components.layout.index.about-us` })},
    ],
    [intl]
  )

  const footerLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: `components.layout.index.items` }),
        links: headerLinks,
      },
      {
        title: intl.formatMessage({ id: `components.layout.index.company` }),
        links: [
          { link: '/legal', label: intl.formatMessage({ id: `components.layout.index.legal` }) },
          { link: '/cookies', label: intl.formatMessage({ id: `components.layout.index.cookies` }) },
          { link: '/certificates', label: intl.formatMessage({ id: `components.layout.index.certificates` }) },
          { link: '/accessibility', label: intl.formatMessage({ id: `components.layout.index.accessibility` }) },
        ],
      },
    ],
    [intl]
  )

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={null}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
        {/*<TramitaHeader links={footerLinks} />*/}
        <Container size={'lg'} className={classes.footerPadding} style={{ height: '100vh' }}>
          <Center style={{ height: '100%' }}>
            <Title align={'center'}>
              ¡Estamos en modo actualización! 🚧 Volvemos el 27 de septiembre con una web tan impresionante como nuestra plataforma. 😉
            </Title>
          </Center>
        </Container>
        {/*<FooterLinks data={footerLinks} />*/}
      </MantineProvider>
    </ColorSchemeProvider>
  )
}

const LayoutIntl = (props) => {
  const intl = props.pageContext.intl
  return (
    // OJO: Se añade el IntlProvider porque al usar un gatsby-browser.tsx personalizado el plugin
    // gatsby-plugin-intl no funcionar correctamente.
    // Los componentes "Link" del plugin tampoco y por ello se debe hacer la chapuza que hay en todos los links,
    // indicando el idioma en el que estamos para que la página dónde queremos ir sea en el mismo.
    // Más: https://github.com/wiziple/gatsby-plugin-intl/issues/60
    <IntlProvider locale={intl.language} defaultLocale={intl.defaultLocale} messages={intl.messages}>
      <LayoutComponent>{props.children}</LayoutComponent>
    </IntlProvider>
  )
}

export const Layout = LayoutIntl
