import React from 'react'
import { ThemeProvider } from './src/providers/ThemeProvider'
import { Layout } from './src/components/Layout'

export const wrapRootElement = ({ element }) => {
  // console.log('wrap')
  return <ThemeProvider>{element}</ThemeProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

// exports.onClientEntry = () => {
//   console.log("We've started!")
// }
