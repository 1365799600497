import React, { createContext, useEffect, useState } from 'react'
import { ColorScheme } from '@mantine/core'

export enum ThemeStyle {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  SYSTEM = 'SYSTEM',
}

export interface ThemeContextType {
  colorScheme: ColorScheme
  theme: ThemeStyle
  setTheme: any
}

export const ThemeContext = createContext({})

/***********************************************************************************************************************
 * Provider para el tema visual.
 * @param children
 * @constructor
 **********************************************************************************************************************/
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  //====================================================================================================================
  //=== States.
  //====================================================================================================================
  const [theme, setTheme] = useState<ThemeStyle>()
  const [colorScheme, setColorScheme] = useState<ColorScheme | null>()

  useEffect(() => {
    let localTheme = window.localStorage.getItem('theme')
    if (!localTheme) {
      localTheme = ThemeStyle.SYSTEM
      window.localStorage.setItem('theme', ThemeStyle.SYSTEM)
    }
    setTheme(localTheme as ThemeStyle)

    if (localTheme === ThemeStyle.SYSTEM) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
      localTheme = prefersDark?.matches ? ThemeStyle.DARK : ThemeStyle.LIGHT
    }
    setColorScheme(localTheme === ThemeStyle.DARK ? 'dark' : 'light')
  }, [])

  useEffect(() => {
    if (theme) {
      let newTheme = theme
      if (theme === ThemeStyle.SYSTEM) {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
        newTheme = prefersDark?.matches ? ThemeStyle.DARK : ThemeStyle.LIGHT
      }
      let storedTheme = window.localStorage.getItem('theme')
      if (theme !== storedTheme) {
        window.localStorage.setItem('theme', theme)
      }
      if (newTheme !== storedTheme) {
        setColorScheme(newTheme === ThemeStyle.DARK ? 'dark' : 'light')
      }
    }
  }, [theme])

  //====================================================================================================================
  //=== Comprobamos que tenemos todos los datos para presentar la app.
  //====================================================================================================================
  if (!colorScheme) {
    return <React.Fragment />
  }

  //====================================================================================================================
  //=== Render.
  //====================================================================================================================
  return <ThemeContext.Provider value={{ colorScheme, theme, setTheme }}>{children}</ThemeContext.Provider>
}

export const useTheme = () => React.useContext(ThemeContext) as ThemeContextType
